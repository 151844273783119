/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Phone from 'img/phone.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const TopBar = styled.div`
  background-color: ${props => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 50px;
  z-index: 2;

  & a {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const StyledHeader = styled.nav`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 50px;
  right: 0;
  height: 78px;
  z-index: 2;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 78px;
    width: 180px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${props => props.theme.color.text.secondary};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const Header = ({ setIsScrollable }) => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 97}) {
        acf {
          top {
            phone
          }
          menu {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <TopBar>
        <Container className="container">
          <div className="row w-100 h-100 align-items-center justify-content-end"> 
            <Phone />
            <a href={`tel:${fields.acf.top.phone}`} className="ml-2">{fields.acf.top.phone}</a>
          </div>
        </Container>
      </TopBar>
      <StyledHeader>
        <Container className="container">
          <Brand to="/">
            <Logo />
          </Brand>

          <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
          <MenuDesktop fields={fields} />
        </Container>
      </StyledHeader>
    </>
  )
}

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${props => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>
          Menu
        </NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
            <NavButtonCloseTitle>
              Sluiten
            </NavButtonCloseTitle>
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  & > a {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.mm};
    color: ${props => props.theme.color.text.light};
    text-transform: uppercase;
    padding: 7px 15px;

    &[aria-current] {
      background-color: ${props => props.theme.color.face.contrast};
    }

    &:hover {
      color: ${props => props.theme.color.text.light};
    }
  }

  &:last-child {
    @media (min-width: 992px){
      display: flex;
      align-items: center;
      height: 78px;
      background-color: ${props => props.theme.color.face.contrast};
      text-align: center;

      & > a {
        padding: 7px 30px;
      }
    }
  }
`

const Menu = ({ fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
