/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import GravityForm from 'components/shared/GravityForm'

// Images
import Logo from 'img/logo.inline.svg'
import Mail from 'img/mail.inline.svg'
import Phone from 'img/phone-footer.inline.svg'
import IconNewsletter from 'img/button-newsletter.svg'

const NewsLetter = styled.div``


const Form = styled(GravityForm)`
  display: flex;
  width: 100%;

  & > div:first-child {
    width: 75%;
  }

  & button[type=submit] {
    padding: 10px 15px 11px;
    color: transparent !important;
    background: url(${IconNewsletter}) no-repeat 50%;
  }
`

const StyledFooter = styled.div`
  background-color: ${props => props.theme.color.face.main};
  color: ${props => props.theme.color.text.light}; 
`

const Title = styled(ParseContent)`
  & h2 {
    & span {
      color: ${props => props.theme.color.text.secondary};
    }
  }
`

const Info = styled.p`
  & span {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.m};
  }
`

const ColumnInfo = styled.div`
  border-left: 1px solid ${props => props.theme.color.face.contrast};
  border-right: 1px solid ${props => props.theme.color.face.contrast};
`

const IconPhone = styled(Phone)`
  width: 10%;
`

const IconMail = styled(Mail)`
  width: 10%;
`

const MenuTitle = styled.h4`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.m};
  color: ${props => props.theme.color.text.light};
`

const FooterMenu = styled.ul`
  list-style: none;
  margin-left: -2.5rem;
`

const CustomLink = styled(Link)`
  line-height: ${props => props.theme.font.size.xl};
`

const Footer = () => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {

          newsletter {
            title
            image {
              localFile {
                publicURL
              }
            }
          }

          columnOne: column_1 {
            button {
              title
              url
            }
          }

          info {
            email
            phone
          }

          assortiment {
            link {
              title
              url
            }
          }

          menu {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <NewsLetter>
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6 mb-4">
              <Title className="mb-4" content={fields.acf.newsletter.title} />
              <Form id={2} />
            </div>
            <div className="col-lg-6 text-center">
              <Image src={fields.acf.newsletter.image.localFile.publicURL} />
            </div>
          </div>
        </div>
      </NewsLetter>
      <StyledFooter>
        <div className="container">
          <div className="row py-5 align-item-center">
            <div className="col-lg-3 mb-4 mb-lg-0">
              <Logo className="d-block mb-4" />
              <ButtonDefault className="ml-2" to={fields.acf.columnOne.button.url}>{fields.acf.columnOne.button.title}</ButtonDefault>
            </div>
            <ColumnInfo className="col-lg-4 px-lg-5 py-3">
              <div className="col-12 d-flex p-0">
                <IconMail />
                <Info className="ml-2">
                  <span className="text-uppercase">Mail ons</span>
                  <br/>
                  <a href={`mailto:${fields.acf.info.email}`}>{fields.acf.info.email}</a>
                </Info>
              </div>
              <div className="col-12 d-flex p-0">
                <IconPhone />
                <Info className="ml-2">
                  <span className="text-uppercase">Bel ons</span>
                  <br/>
                  <a href={`mailto:${fields.acf.info.phone}`}>{fields.acf.info.phone}</a>
                </Info>
              </div>
            </ColumnInfo>
            <div className="col-lg-5 d-flex flex-row flex-wrap px-lg-5 py-3">
              <div className="col-lg-6">
                <MenuTitle>Assortiment</MenuTitle>
                <FooterMenu>
                  {fields.acf.assortiment.map(({ link: { url, title } }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <CustomLink to={url}>
                        {title}
                      </CustomLink>
                    </li>
                  ))}
                </FooterMenu>
              </div>
              <div className="col-lg-6">
                <MenuTitle>Menu</MenuTitle>
                <FooterMenu>
                  {fields.acf.menu.map(({ link: { url, title } }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <CustomLink to={url}>
                        {title}
                      </CustomLink>
                    </li>
                  ))}
                </FooterMenu>
              </div>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
