import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#15428E',
      contrast: '#1999D6',
      secondary: '#FFFFFF',
      grey: '#D0D0D0',
      light: '#FFFFFF'
    },

    text: {
      main: '#000000',
      contrast: '#15428E',
      secondary: '#14A4DE',
      blue: '#154C9C',
      light: '#FFFFFF'
    },
  },

  font: {
    family: {
      main: 'Helvetica LT W01 Roman',
      bold: 'Helvetica LT W01 Bold',
      secondary: 'Bebas Neue',
    },      

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      mm: '18px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xml: '36px',
      xxl: '44px',
      xsl: '60px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${props => props.isScrollable ? 'initial' : 'hidden'};
  }

  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.main};
    letter-spacing: 0.6px;
    line-height: 25px;
  }

  h1,h2,h3,h4 {
    font-family: ${props => props.theme.font.family.secondary};
    color: ${props => props.theme.color.text.contrast};
    letter-spacing: 2.29px;
  }

  ${props => Object.keys(props.theme.color.face).map(key => {
    return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
  })}

  ${props => Object.keys(props.theme.color.text).map(key => {
    return `.color-${key} { color: ${props.theme.color.text[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.family).map(key => {
    return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.size).map(key => {
    return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.weight).map(key => {
    return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
  })}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children, isScrollable }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme