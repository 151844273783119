import React from 'react'
import styled, { css } from 'styled-components'
import Image from 'gatsby-image'

const ImageCSS = css`
  
`

const DOMImage = styled.img`
  ${ImageCSS};
`

const GatsbyImage = styled(Image)`
  ${ImageCSS};
`

const Media = ({ className, src, style, alt, aboveFold }) => {
  const regular = !src.localFile || (src.url && src.url.localFile && src.url.localFile.publicURL) || (src.localFile && src.localFile.publicURL)

  if(regular) {
    let url = ''

    if(typeof src === 'string') {
      url = src
    } else if(src.url && src.url.localFile && src.url.localFile.publicURL) {
      url = src.url.localFile.publicURL
    } else if(src.localFile && src.localFile.publicURL) {
      url = src.localFile.publicURL
    } else {
      url = src.url
    }

    return (
      <div
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
        className="gatsby-image-wrapper"
      >
        <picture>
          <DOMImage
            className={className}
            src={url}
            style={style}
            alt={alt}
          />
        </picture>
      </div>
    )
  }

  return (
    <GatsbyImage 
      className={className}
      fluid={src.localFile.childImageSharp.fluid} 
      style={style}
      fadeIn={!aboveFold}
      loading={aboveFold ? 'eager' : 'lazy'}
    />
  )
}

export default Media
