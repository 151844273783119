/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  display: inline-block;
  transition: .2s;

  ${props => props.dark && css`
    background-color: ${props => props.theme.color.face.main};
  `}
  
  & > a, & > button {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xm};
    color: ${props => props.theme.color.text.light} !important;
    letter-spacing: 1.43px;
    padding: 11px 35px 8px;
    width: 100%;
    height: 100%;
    display: block;
    min-width: 180px;
    text-align: center;
    
    @media (max-width: 991px){
      font-size: ${props => props.theme.font.size.mm};
    }
    
  }

  &:hover {
    transform: scale(1.05);
  }

`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className, dark, target }) => (
  <StyledButton className={className} dark={dark}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault